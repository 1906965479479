import React, { Component } from "react";
import TimeComponent from "./components/time_comp";
import { Helmet } from "react-helmet";

import ReactPlayer from "react-player";
import "./index.css";

import { Tweet } from "react-tweet";
import ReactGA from "react-ga4";
ReactGA.initialize("G-49G513MGX9");

// const version = "123123s";
class App extends Component {
  state = {
    url: "https://www.youtube.com/watch?v=y6120QOlsfU&t=2s",
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  changeUrl = () => {
    console.log("changeUrl");
    console.log(this.state.url);
    this.setState({
      url: "https://www.youtube.com/watch?v=MN3x-kAbgFU&ab_channel=RewindMusic",
    });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  // handleClickFullscreen = () => {
  //   screenfull.request(document.querySelector(".react-player"));
  // };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      playbackRate,
      pip,
    } = this.state;
    /* The line `const SEPARATOR = " · ";` is declaring a constant variable named `SEPARATOR` and
    assigning it the value `" · "`. This constant is likely intended to be used as a separator in
    the code or in the user interface to visually separate or distinguish different elements or
    sections. It can be used to improve readability or provide a visual cue to the user. */
    // const SEPARATOR = " · ";

    return (
      <div className="app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>If you play X at </title>
          <link rel="canonical" href="http://ifyouplayxat.com/" />
        </Helmet>
        <section className="section">
          <div class="grid grid-cols-2">
            <div>
              {" "}
              <div className="mx-auto p-10 w-[100%] h-[500px] my-24 shadow-2xl rounded-lg">
                <ReactPlayer
                  ref={this.ref}
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={url}
                  pip={pip}
                  playing={playing}
                  controls={controls}
                  light={light}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  onReady={() => console.log("onReady")}
                  onStart={() => console.log("onStart")}
                  onPlay={this.handlePlay}
                  onEnablePIP={this.handleEnablePIP}
                  onDisablePIP={this.handleDisablePIP}
                  onPause={this.handlePause}
                  onBuffer={() => console.log("onBuffer")}
                  onPlaybackRateChange={this.handleOnPlaybackRateChange}
                  onSeek={(e) => console.log("onSeek", e)}
                  onEnded={this.handleEnded}
                  onError={(e) => console.log("onError", e)}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                  onPlaybackQualityChange={(e) =>
                    console.log("onPlaybackQualityChange", e)
                  }
                />
              </div>{" "}
            </div>
            <div>
              <div className="">
                {" "}
                <TimeComponent />
                <div class="playlist mx-10 flex overflow-y-scroll h-96 bg-gray-100 justify-center ">
                  <div className="light">
                    <div className="" onClick={this.changeUrl}>
                      <Tweet id="943876830947405824" />
                    </div>
                    <div>
                      <Tweet id="943876830947405824" />
                    </div>
                    <Tweet id="943876830947405824" />
                    <Tweet id="943876830947405824" />
                  </div>
                </div>
              </div>
              <div className="p-5 mx-10 my-10 bg-blue-100 rounded-lg shadow-xl">
                <div className="mx-5">
                  {" "}
                  <table>
                    <tbody>
                      <tr className="px-10">
                        <th>Controls</th>
                        <td>
                          <button onClick={this.handleStop}>Stop</button>
                          <button onClick={this.handlePlayPause}>
                            {playing ? "Pause" : "Play"}
                          </button>
                          <button onClick={this.handleClickFullscreen}>
                            Fullscreen
                          </button>
                          {light && (
                            <button onClick={() => this.player.showPreview()}>
                              Show preview
                            </button>
                          )}
                          {ReactPlayer.canEnablePIP(url) && (
                            <button onClick={this.handleTogglePIP}>
                              {pip ? "Disable PiP" : "Enable PiP"}
                            </button>
                          )}
                        </td>
                      </tr>
                      <tr className="px-10">
                        <th>Speed</th>
                        <td>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={1}
                          >
                            1x
                          </button>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={1.5}
                          >
                            1.5x
                          </button>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={2}
                          >
                            2x
                          </button>
                        </td>
                      </tr>
                      <tr className="px-10">
                        <th>Seek</th>
                        <td>
                          <input
                            type="range"
                            min={0}
                            max={0.999999}
                            step="any"
                            value={played}
                            onMouseDown={this.handleSeekMouseDown}
                            onChange={this.handleSeekChange}
                            onMouseUp={this.handleSeekMouseUp}
                          />
                        </td>
                      </tr>
                      <tr className="px-10">
                        <th>Volume</th>
                        <td>
                          <input
                            type="range"
                            min={0}
                            max={1}
                            step="any"
                            value={volume}
                            onChange={this.handleVolumeChange}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>{" "}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default App;
